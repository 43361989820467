import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "progress-wrap margin-bottom-28" }
const _hoisted_9 = { class: "title mb-3" }
const _hoisted_10 = { class: "subtitle mb-3" }
const _hoisted_11 = { class: "r-font-size-200 w-100" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "card-footer" }
const _hoisted_14 = { class: "footer" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-sm-auto mb-3" }
const _hoisted_17 = { class: "col-sm-auto order-sm-first" }
const _hoisted_18 = { class: "title mb-3" }
const _hoisted_19 = { class: "subtitle mb-3" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-sm-auto mb-3" }
const _hoisted_22 = { class: "col-sm-auto order-sm-first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_r_progress_step = _resolveComponent("r-progress-step")!
  const _component_r_progress_steps = _resolveComponent("r-progress-steps")!
  const _component_r_button = _resolveComponent("r-button")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Logo, {
                  logoUrl: _ctx.data.clientLogoUrl
                }, null, 8, ["logoUrl"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.hasSteps())
                    ? (_openBlock(), _createBlock(_component_r_progress_steps, {
                        key: 0,
                        "complete-steps": "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_r_progress_step, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpStart")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_r_progress_step, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation(_ctx.selectedOtpType == _ctx.OtpType.Email ? "OtpEmailCode" : "OtpSmsCode")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_r_progress_step, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpBirthDateLabel")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.getTranslation(_ctx.hasSteps() ? "OtpEnterYourDob" : "OtpConfirmDobOnly")), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.getTranslation(_ctx.hasSteps() ? "OtpConfirmDob" : "OtpConfirmDobOnlyDescription")), 1),
                _createElementVNode("label", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpDobLabel")) + " ", 1),
                  _withDirectives(_createElementVNode("input", {
                    id: "birthDateInput",
                    class: _normalizeClass(["r-input", {
                                            check: _ctx.birthDateIsValid,
                                            'r-input-error': _ctx.birthDateInputError,
                                        }]),
                    "data-maska": "##/##/####",
                    placeholder: _ctx.getBirthDatePlaceholder(),
                    inputmode: "numeric"
                  }, null, 10, _hoisted_12), [
                    [_directive_maska, _ctx.birthDateMaskaOptions]
                  ]),
                  _createElementVNode("span", {
                    class: _normalizeClass(["hint", { 'hint-error': _ctx.birthDateInputError }])
                  }, _toDisplayString(_ctx.birthDateHintMessage), 3)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_r_button, {
                        id: "continueBtn",
                        class: "footer-button",
                        expanded: "",
                        disabled: !_ctx.birthDateIsValid,
                        onClick: _ctx.validateDOB,
                        onKeyup: _withKeys(_ctx.validateDOB, ["enter"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpContinueButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick", "onKeyup"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      (_ctx.hasSteps())
                        ? (_openBlock(), _createBlock(_component_r_button, {
                            key: 0,
                            href: "#modal-content",
                            class: "footer-button",
                            variant: "secondary",
                            expanded: "",
                            onClick: _ctx.confirmExit
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpClose")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (!_ctx.hasSteps())
                        ? (_openBlock(), _createBlock(_component_r_button, {
                            key: 1,
                            class: "footer-button",
                            variant: "secondary",
                            expanded: "",
                            onClick: _ctx.goBack
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpBackButton")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.confirmExitOpen)
              ? (_openBlock(), _createBlock(_component_ModalComponent, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.getTranslation("OtpExitVerificationPrompt")), 1),
                      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.getTranslation("OtpExitVerificationDescription")), 1),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_component_r_button, {
                            id: "exit",
                            class: "footer-button",
                            expanded: "",
                            onClick: _ctx.goBack
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpExitVerification")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_r_button, {
                            class: "footer-button",
                            variant: "secondary",
                            expanded: "",
                            onClick: _ctx.closeModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpContinueVerification")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}