enum SignicatStatusType {
    NotInitiated, //if status is NotInitiated no point of polling Signicat
    ScaPageOpened, //customer is redirected to SCA page, but /authorize call is not yet send
    Initiated,
    Started, //The order is pending. A client has been started with the auto start token but a usable ID has not yet been found in the started client
    OutstandingTransaction,
    UserSign,
    UserCancel, //The user decided to cancel the order
    Cancelled, //The order was cancelled. The system received a new order for the user.
    Expired,
    NoClient, // user has not starter BankId client
    SignicatComplete,
    SsnVerificationComplete,
    Failure,
    ZoralCallPending,
    IdentityVerified,
    IdentityCaptured,
    IdentityMismatch,
    VerificationCodeRequested,
    VerificationCompleted,
    UnderRetry,
}

export default SignicatStatusType;
