enum LanguageCode {
    Undefined,
    EN = 5,
    DE = 6,
    NL = 7,
}

function languageCodeStringToEnum(language: string | null | undefined) {
    if (!language) return LanguageCode.EN;

    switch (language) {
        case "en":
            return LanguageCode.EN;
        case "nl":
            return LanguageCode.NL;
        case "de":
            return LanguageCode.DE;
        default:
            return LanguageCode.EN;
    }
}

function languageCodeEnumToString(language: LanguageCode | null) {
    if (!language) return "en";

    switch (language) {
        case LanguageCode.EN:
            return "en";
        case LanguageCode.DE:
            return "de";
        case LanguageCode.NL:
            return "nl";
        default:
            return "en";
    }
}

export { LanguageCode, languageCodeStringToEnum, languageCodeEnumToString };
