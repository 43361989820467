enum ScaType {
    None,
    SwedishHeadlessBankId,
    SwedishBankId,
    Idin,
    MitId,
    Ftn,
    NorwegianBankId,
    Otp,
}

export default ScaType;
