enum FlowType {
    VerifySsn,
    VerifySsnHeadless,
    VerifyName,
    CaptureIdentity,
    VerifyOtpSms,
    VerifyOtpEmail,
    VerifyOtpDoB,
    VerifyOtpSmsEmail,
    VerifyOtpSmsDob,
    VerifyOtpEmailDob,
}

function flowTypeEnumToString(flowType: FlowType | string) {
    switch (flowType) {
        case FlowType.VerifyOtpEmail:
            return "VerifyOtpEmail";
        case FlowType.VerifyOtpSms:
            return "VerifyOtpSms";
        case FlowType.VerifyOtpSmsEmail:
            return "VerifyOtpSmsEmail";
        case FlowType.VerifyOtpDoB:
            return "VerifyOtpDoB";
        case FlowType.VerifyOtpSmsDob:
            return "VerifyOtpSmsDob";
        case FlowType.VerifyOtpEmailDob:
            return "VerifyOtpEmailDob";
        default:
            return "Unset";
    }
}

export { FlowType, flowTypeEnumToString };
