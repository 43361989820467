import { FlowType, flowTypeEnumToString, LanguageCode, OtpType, SignicatStatusType, languageCodeStringToEnum, strToEnum } from "@/enums";
import { getTextResources } from "tests/entities";
import { CheckoutInfo, TextResourceList } from "@/entities";
import { get } from "@/utils/httpRequest";
import { getTranslations, loading, replaceState, translation } from "@/utils/common";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "ConfirmView",
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new CheckoutInfo(),
            textResourceList: new TextResourceList(),
            goBackHref: <string | null>"",
            selectedOtpType: <OtpType | string>OtpType.Unset,
            isCodeEntered: false,
            interval: <NodeJS.Timeout>(<unknown>0),
            OtpType,
            store: useStore(otpKey),
        };
    },
    created() {
        replaceState();
        this.data = this.store.state.otp;

        if (this.data.signicatStatus == SignicatStatusType.Expired) {
            this.setCodeInputError("OtpCodeErrorExpired");
        }

        this.selectedOtpType = strToEnum(<string>this.data.otpInfo[this.data.otpInfo.length - 1].otpType);
        this.goBackHref = !this.data.email || !this.data.phoneNumber ? this.data.redirectUrl : `/otp/verifyotpauthentication/init/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`;
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const codeInput = <HTMLInputElement>document.getElementById("code");

        if (code) {
            codeInput.value = code;

            if (code.length == 6) {
                const nextBtn = <HTMLButtonElement>document.getElementById("nextBtn");
                nextBtn.removeAttribute("disabled");
            }
        } else {
            codeInput.focus();
        }

        if (!this.data.isResentCode) {
            this.startTimerCountdown();
        }

        this.getData();
    },
    methods: {
        async getData() {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.data.countryCode, languageCodeStringToEnum(this.data.conversationLanguage))) as TextResourceList;
            } catch {
                return;
            } finally {
                loading();
            }
        },
        hasSteps() {
            return this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmailDob) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsDob);
        },
        pastedCode(e: ClipboardEvent) {
            e.stopPropagation();
            e.preventDefault();

            const clipboardData = e.clipboardData;

            if (clipboardData) {
                const code = <HTMLInputElement>document.getElementById("code");
                code.value = clipboardData.getData("Text");

                this.validateNextBtn();
            }
        },
        startTimerCountdown() {
            const requestNewCodeBtn = <HTMLButtonElement>document.getElementById("requestNewCodeBtn");
            requestNewCodeBtn.setAttribute("disabled", "disabled");

            let countdownSeconds = 10;
            const vm = this;

            this.interval = setInterval(function () {
                if (countdownSeconds > 0) {
                    countdownSeconds--;
                } else {
                    clearInterval(vm.interval);
                    requestNewCodeBtn.removeAttribute("disabled");
                    vm.data.isResentCode = false;
                }
            }, 1000);
        },
        validateNextBtn() {
            const codeInput = <HTMLInputElement>document.getElementById("code");
            const nextBtn = <HTMLButtonElement>document.getElementById("nextBtn");

            const code = codeInput?.value.trim();

            if (code.length == 6) {
                codeInput.classList.add("is-valid");
                nextBtn.removeAttribute("disabled");
            } else {
                codeInput.classList.remove("is-valid");
                nextBtn.setAttribute("disabled", "disabled");
            }
        },
        setCodeInputError(errorCode: string, inputElement: HTMLInputElement | null = null) {
            if (!inputElement) {
                inputElement = <HTMLInputElement>document.getElementById("code");
            }
            const inputErrorClass = [];
            const errorClass = ["r-error"];

            switch (errorCode) {
                case "OtpCodeErrorFailed":
                    inputErrorClass.push("r-input-error");
                    break;
                case "OtpCodeErrorFailedTwice":
                    errorClass.push("toasty");
                    break;
                default:
                    inputErrorClass.push("r-input-error");
                    break;
            }

            inputElement.classList.add(...inputErrorClass);

            const errorElement = <HTMLElement>document.getElementById("codeError");
            if (errorElement) {
                errorElement.classList.remove("hidden");
                errorElement.classList.add(...errorClass);
            }

            const errorLabelElement = <HTMLElement>document.getElementById("codeErrorLabel");
            if (errorLabelElement) {
                errorLabelElement.innerText = this.getTranslation(errorCode);
            }
        },
        clearCodeInputError(inputElement: HTMLInputElement | null = null) {
            if (!inputElement) {
                inputElement = <HTMLInputElement>document.getElementById("code");
            }
            inputElement.classList.remove("r-input-error");

            const errorElement = <HTMLElement>document.getElementById("codeError");
            if (errorElement) {
                errorElement.classList.add("hidden");
                errorElement.classList.remove("toasty");
            }

            const errorLabelElement = <HTMLElement>document.getElementById("codeErrorLabel");
            if (errorLabelElement) {
                errorLabelElement.innerText = "";
            }
        },
        async validateCode() {
            const inputElement = <HTMLInputElement>document.getElementById("code");

            if (inputElement) {
                this.clearCodeInputError(inputElement);
                const value = inputElement.value;

                if (value.trim().length == 6) {
                    const response = await get(`api/verifyotp/validatecode/${this.selectedOtpType}/${this.scaSessionId}?code=${value}`);
                    if (!response.isSuccess) {
                        //redirect to error view
                        this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`);
                    }

                    this.isCodeEntered = true;
                    this.data.isResentCode = response.isResentCode;

                    //redirect back to merchant
                    if (this.data.redirectUrl && response.returnToMerchant) {
                        window.location.replace(this.data.redirectUrl);
                    }

                    if (response.message) {
                        //when code was wrong twice
                        if (response.message == "OtpCodeErrorFailedTwice") {
                            //enable request new button
                            clearInterval(this.interval);
                            const requestNewCodeBtn = <HTMLButtonElement>document.getElementById("requestNewCodeBtn");
                            requestNewCodeBtn.removeAttribute("disabled");

                            //hide next button
                            const nextBtn = <HTMLButtonElement>document.getElementById("nextBtn");
                            nextBtn.classList.add("hidden");
                        }
                        this.setCodeInputError(response.message, inputElement);
                        return;
                    }

                    //redirect to next step
                    if (response.view) {
                        this.$router.replace(`/${response.view}/${this.scaSessionId}`);
                    }
                } else {
                    setTimeout(() => {
                        inputElement.classList.add("r-input-error");
                    }, 0);
                }
            }
        },
        async reSend() {
            //reset code input element
            const inputElement = <HTMLInputElement>document.getElementById("code");
            this.clearCodeInputError(inputElement);
            inputElement.value = "";

            //un-hide and disable next button
            const nextBtn = <HTMLButtonElement>document.getElementById("nextBtn");
            nextBtn.classList.remove("hidden");
            nextBtn.setAttribute("disabled", "disabled");

            //restart counter
            this.startTimerCountdown();

            this.isCodeEntered = false;

            const response = await get(`api/verifyotp/requestcode/${this.selectedOtpType}/${this.scaSessionId}?reSendCode=true`);
            if (!response.isSuccess) {
                //redirect to error view
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`);
            }

            //redirect back to merchant
            if (this.data.redirectUrl && response.returnToMerchant) {
                window.location.replace(this.data.redirectUrl);
            }

            //redirect to next step
            if (response.view) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}`);
            }
        },
        goBack() {
            if (this.hasSteps()) {
                //redirect to prevoius step
                this.$router.replace(`/otp/verifyotpauthentication/init/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`);
                return;
            }
            if (this.goBackHref) {
                window.location.replace(this.goBackHref);
            }
        },
        async getTextResources(countryCode: string | null, languageCode: LanguageCode, agent: unknown | null = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "OtpBackButton",
                "OtpEnterYourCode",
                "OtpWeSentCodeTo",
                "OtpCodeLabel",
                "OtpCodeEmailInfo",
                "OtpCodeSmsInfo",
                "OtpCodeErrorExpired",
                "OtpCodeErrorFailed",
                "OtpCodeErrorFailedTwice",
                "OtpContinueButton",
                "OtpReSendCodeButton",
                "OtpStart",
                "OtpEmailCode",
                "OtpSmsCode",
                "OtpBirthDateLabel",
            ];

            return await getTranslations(sysnameList, countryCode, languageCode, agent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
