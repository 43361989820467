import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icon-success.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container margin-top-56" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Logo, {
                  logoUrl: _ctx.data.clientLogoUrl
                }, null, 8, ["logoUrl"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  class: "align-items-start margin-bottom-28",
                  src: _imports_0,
                  alt: "Success",
                  width: "100"
                }, null, -1)),
                _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.getTranslation("OtpAlreadyCompletedTitle")), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.getTranslation("OtpAlreadyCompletedSubTitle")), 1)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}