<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container margin-top-56">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <Logo :logoUrl="data.clientLogoUrl" />
                            </div>
                            <div class="card-body">
                                <img class="align-items-start margin-bottom-28" src="@/assets/icon-success.svg" alt="Success" width="88" />
                                <h5 class="title">{{ getTranslation(hasDoBStep() ? "OtpThankYou" : "OtpSuccessTitle") }}</h5>
                                <p class="mb-3" v-if="hasDoBStep()">{{ getTranslation("OtpWeAreReviewingInformation") }}</p>
                                <p id="description" class="mb-3"></p>
                                <p v-html="getTranslation('OtpSuccessDescription', [data?.redirectUrl ?? 'https://riverty.com'])"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
