<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <Logo :logoUrl="data.clientLogoUrl" />
                            </div>
                            <div class="card-body">
                                <div class="progress-wrap margin-bottom-28">
                                    <r-progress-steps v-if="hasSteps()" complete-steps="0">
                                        <r-progress-step>{{ getTranslation("OtpStart") }}</r-progress-step>
                                        <r-progress-step>{{ getTranslation(selectedOtpType == OtpType.Email ? "OtpEmailCode" : "OtpSmsCode") }}</r-progress-step>
                                        <r-progress-step>{{ getTranslation("OtpBirthDateLabel") }}</r-progress-step>
                                    </r-progress-steps>
                                </div>
                                <h5 class="title">{{ getTranslation("OtpIntroTitle") }}</h5>
                                <p class="subtitle margin-bottom-40">
                                    {{ getTranslation("OtpIntroDescription") }}
                                </p>
                                <p class="subtitle margin-bottom-40">
                                    {{ getTranslation("OtpIntroSmsAndEmailDescription") }}
                                </p>
                                <form>
                                    <div class="mb-4">
                                        <r-radio-button
                                            id="emailRadio"
                                            name="otpTypeRadio"
                                            :value="OtpType.Email"
                                            :disabled="emailDisabled"
                                            :checked="selectedOtpType == OtpType.Email"
                                            v-on:keyup.space="changeOtpType(OtpType.Email)"
                                            v-on:keyup.enter="changeOtpType(OtpType.Email)"
                                            v-on:click="changeOtpType(OtpType.Email)"
                                            >{{ getTranslation("OtpSelectMethodViaEmail") }} {{ data.email }}
                                        </r-radio-button>
                                    </div>
                                    <div>
                                        <r-radio-button
                                            id="smsRadio"
                                            name="otpTypeRadio"
                                            :value="OtpType.Sms"
                                            :disabled="smsDisabled"
                                            :checked="selectedOtpType == OtpType.Sms"
                                            v-on:keyup.space="changeOtpType(OtpType.Sms)"
                                            v-on:keyup.enter="changeOtpType(OtpType.Sms)"
                                            v-on:click="changeOtpType(OtpType.Sms)"
                                            >{{ getTranslation("OtpSelectMethodViaSms") }} {{ data.phoneNumber }}
                                        </r-radio-button>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-sm-auto mb-3">
                                            <r-button id="redirectBtn" class="footer-button" expanded="" disabled v-on:click="redirect" v-on:keyup.enter="redirect">{{ getTranslation("OtpRequestCode") }}</r-button>
                                        </div>
                                        <div class="col-sm-auto order-sm-first">
                                            <r-button class="footer-button order-md-1" variant="secondary" expanded="" v-on:click="goBack">{{ getTranslation("OtpClose") }}</r-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
