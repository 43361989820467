<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <Logo :logoUrl="data.clientLogoUrl" />
                            </div>
                            <div class="card-body">
                                <div class="progress-wrap margin-bottom-28">
                                    <r-progress-steps v-if="hasSteps()" complete-steps="0">
                                        <r-progress-step>{{ getTranslation("OtpStart") }}</r-progress-step>
                                        <r-progress-step>{{ getTranslation(selectedOtpType == OtpType.Email ? "OtpEmailCode" : "OtpSmsCode") }}</r-progress-step>
                                        <r-progress-step>{{ getTranslation("OtpBirthDateLabel") }}</r-progress-step>
                                    </r-progress-steps>
                                </div>
                                <h5 class="title">{{ getTranslation("OtpIntroTitle") }}</h5>
                                <p class="subtitle margin-bottom-40">
                                    {{ getTranslation("OtpIntroDescription") }}
                                </p>

                                <p class="subtitle margin-bottom-40" v-show="selectedOtpType == OtpType.Email && !hasSteps()">{{ getTranslation("OtpIntroEmailDescription") }} {{ data.email }}</p>
                                <p class="subtitle margin-bottom-40" v-show="selectedOtpType == OtpType.Email && hasSteps()">{{ getTranslation("OtpIntroEmailDobDescription") }}</p>
                                <p class="subtitle margin-bottom-40" v-show="selectedOtpType == OtpType.Sms && !hasSteps()">{{ getTranslation("OtpIntroSmsDescription") }} {{ data.phoneNumber }}</p>
                                <p class="subtitle margin-bottom-40" v-show="selectedOtpType == OtpType.Sms && hasSteps()">{{ getTranslation("OtpIntroSmsDobDescription") }}</p>

                                <p class="subtitle margin-bottom-40" v-show="hasSteps()">
                                    {{ getTranslation("OtpIntroWeSendVerificationCodeTo") }} <span v-show="selectedOtpType == OtpType.Sms">{{ data.phoneNumber }}</span
                                    ><span v-show="selectedOtpType == OtpType.Email">{{ data.email }}</span>
                                </p>
                            </div>
                            <div class="card-footer">
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-sm-auto mb-3">
                                            <r-button class="footer-button" expanded="" v-on:click="redirect" v-on:keyup.enter="redirect">{{ getTranslation("OtpIntroSendCodeButton") }}</r-button>
                                        </div>
                                        <div class="col-sm-auto order-sm-first">
                                            <r-button class="footer-button" variant="secondary" expanded="" @click="goBack">{{ getTranslation("OtpBackButton") }}</r-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
