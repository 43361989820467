import { defineComponent } from "vue";

export default defineComponent({
    name: "ModalComponent",
    methods: {
        handleClick() {
            document.body.style.overflow = "auto";
        },
    },
});
