<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <Logo :logoUrl="data.clientLogoUrl" />
                            </div>
                            <div class="card-body">
                                <div class="progress-wrap margin-bottom-28">
                                    <r-progress-steps v-if="hasSteps()" complete-steps="2">
                                        <r-progress-step>{{ getTranslation("OtpStart") }}</r-progress-step>
                                        <r-progress-step>{{ getTranslation(selectedOtpType == OtpType.Email ? "OtpEmailCode" : "OtpSmsCode") }}</r-progress-step>
                                        <r-progress-step>{{ getTranslation("OtpBirthDateLabel") }}</r-progress-step>
                                    </r-progress-steps>
                                </div>
                                <h5 class="title mb-3">{{ getTranslation(hasSteps() ? "OtpEnterYourDob" : "OtpConfirmDobOnly") }}</h5>
                                <p class="subtitle mb-3">
                                    {{ getTranslation(hasSteps() ? "OtpConfirmDob" : "OtpConfirmDobOnlyDescription") }}
                                </p>
                                <label class="r-font-size-200 w-100">
                                    {{ getTranslation("OtpDobLabel") }}
                                    <input
                                        id="birthDateInput"
                                        class="r-input"
                                        :class="{
                                            check: birthDateIsValid,
                                            'r-input-error': birthDateInputError,
                                        }"
                                        v-maska="birthDateMaskaOptions"
                                        data-maska="##/##/####"
                                        v-bind:placeholder="getBirthDatePlaceholder()"
                                        inputmode="numeric" />
                                    <span class="hint" :class="{ 'hint-error': birthDateInputError }">{{ birthDateHintMessage }}</span>
                                </label>
                            </div>
                            <div class="card-footer">
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-sm-auto mb-3">
                                            <r-button id="continueBtn" class="footer-button" expanded="" :disabled="!birthDateIsValid" @click="validateDOB" v-on:keyup.enter="validateDOB">{{ getTranslation("OtpContinueButton") }}</r-button>
                                        </div>
                                        <div class="col-sm-auto order-sm-first">
                                            <r-button v-if="hasSteps()" href="#modal-content" class="footer-button" variant="secondary" expanded="" @click="confirmExit">{{ getTranslation("OtpClose") }}</r-button>
                                            <r-button v-if="!hasSteps()" class="footer-button" variant="secondary" expanded="" @click="goBack">{{ getTranslation("OtpBackButton") }}</r-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalComponent v-if="confirmExitOpen">
                            <div>
                                <p class="title mb-3">{{ getTranslation("OtpExitVerificationPrompt") }}</p>
                                <p class="subtitle mb-3">{{ getTranslation("OtpExitVerificationDescription") }}</p>
                                <div class="row">
                                    <div class="col-sm-auto mb-3">
                                        <r-button id="exit" class="footer-button" expanded="" @click="goBack">{{ getTranslation("OtpExitVerification") }}</r-button>
                                    </div>
                                    <div class="col-sm-auto order-sm-first">
                                        <r-button class="footer-button" variant="secondary" expanded="" @click="closeModal">{{ getTranslation("OtpContinueVerification") }}</r-button>
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
