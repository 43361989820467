import { CheckoutInfo } from "./checkoutInfo";

class OtpResponse {
    isSuccess = true;
    isDeadEnd = false;
    message: string | null = null;
    view: string | null = null;
    returnToMerchant = false;
    data: CheckoutInfo | null = null;
}

export { OtpResponse };
