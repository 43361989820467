import { defineComponent } from "vue";
import { FlowType, flowTypeEnumToString, LanguageCode, languageCodeStringToEnum } from "@/enums";
import { getTextResources } from "tests/entities";
import { CheckoutInfo, TextResourceList } from "@/entities";
import { getTranslations, loading, replaceState, translation } from "@/utils/common";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "SuccessView",
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new CheckoutInfo(),
            textResourceList: new TextResourceList(),
            store: useStore(otpKey),
        };
    },
    created() {
        replaceState();
        this.data = this.store.state.otp;
    },
    async mounted() {
        // eslint-disable-next-line
        // @ts-ignore: expose redirect function to window
        window.redirect = this.redirect;

        await this.getData();

        const description = document.getElementById("description");
        let seconds = 5;

        if (!description) return;

        let innerHtml = this.getTranslation("OtpYouWillBeRedirected");
        innerHtml = innerHtml.replace(/(\d+)/, seconds.toString());
        description.innerHTML = innerHtml;

        const vm = this;
        const interval = setInterval(function () {
            if (seconds <= 0) {
                clearInterval(interval);
                vm.redirect();
            } else {
                innerHtml = innerHtml.replace(/(\d+)/, (seconds--).toString());
                description.innerHTML = innerHtml;
            }
        }, 1000);
    },
    methods: {
        redirect() {
            if (this.data.redirectUrl) {
                window.location.replace(this.data.redirectUrl);
            }
        },
        async getData() {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.data.countryCode, languageCodeStringToEnum(this.data.conversationLanguage))) as TextResourceList;
            } catch {
                return;
            } finally {
                loading();
            }
        },
        hasDoBStep() {
            return this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpDoB) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmailDob) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsDob);
        },
        async getTextResources(countryCode: string | null | undefined, languageCode: LanguageCode, agent: unknown | null = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["OtpThankYou", "OtpSuccessTitle", "OtpWeAreReviewingInformation", "OtpYouWillBeRedirected", "OtpSuccessDescription"];

            return await getTranslations(sysnameList, countryCode, languageCode, agent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
