import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { CompletedView, ConfirmView, DateOfBirthView, ErrorView, ExpiredView, InitView, IntroView, SelectOtpView, SuccessView } from "@/views";

const basePath = "/otp/";
const createPath = (path: string) => `${basePath}${path}`;

const routes: Array<RouteRecordRaw> = [
    {
        path: createPath("verifyotpauthentication/init/:scaSessionId"),
        name: "One Time Passcode - Init",
        props: true,
        component: InitView,
    },
    {
        path: createPath("verifyDoB/:scaSessionId"),
        name: "One Time Passcode - Confirm date of birth",
        props: true,
        component: DateOfBirthView,
    },
    {
        path: createPath("selectOtpType/:scaSessionId"),
        name: "One Time Passcode - Select OTP Type",
        props: true,
        component: SelectOtpView,
    },
    {
        path: createPath("confirm/:scaSessionId"),
        name: "One Time Passcode - Confirm",
        props: true,
        component: ConfirmView,
    },
    {
        path: createPath("success/:scaSessionId"),
        name: "One Time Passcode - Success",
        props: true,
        component: SuccessView,
    },
    {
        path: createPath("completed"),
        name: "One Time Passcode - Completed",
        component: CompletedView,
    },
    {
        path: createPath("error/:scaSessionId"),
        name: "One Time Passcode - Error",
        props: true,
        component: ErrorView,
    },
    {
        path: createPath("intro/:scaSessionId"),
        props: true,
        name: "One Time Passcode - Intro",
        component: IntroView,
    },
    {
        path: createPath("expired/:scaSessionId"),
        props: true,
        name: "One Time Passcode - Expired",
        component: ExpiredView,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Page Not Found",
        component: ErrorView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, _, next) => {
    if (!to.path.startsWith(basePath)) {
        const newPath = `${basePath}/${to.path}`.replace(/\/{2,}/g, "/");
        next({ path: newPath, replace: true, query: to.query });
    } else {
        if (to.name) {
            document.title = to.name.toString();
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = to.name.toString();
            document.head.appendChild(meta);
        }
        next();
    }
});

export default router;
