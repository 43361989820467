import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "progress-wrap margin-bottom-28" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "subtitle margin-bottom-40" }
const _hoisted_11 = { class: "subtitle margin-bottom-40" }
const _hoisted_12 = { class: "card-footer" }
const _hoisted_13 = { class: "footer" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-sm-auto mb-3" }
const _hoisted_16 = { class: "col-sm-auto order-sm-first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_r_progress_step = _resolveComponent("r-progress-step")!
  const _component_r_progress_steps = _resolveComponent("r-progress-steps")!
  const _component_r_button = _resolveComponent("r-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Logo, {
                  logoUrl: _ctx.data.clientLogoUrl
                }, null, 8, ["logoUrl"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.hasSteps())
                    ? (_openBlock(), _createBlock(_component_r_progress_steps, {
                        key: 0,
                        "complete-steps": "0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_r_progress_step, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpStart")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_r_progress_step, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation(_ctx.selectedOtpType == _ctx.OtpType.Email ? "OtpEmailCode" : "OtpSmsCode")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_r_progress_step, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpBirthDateLabel")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.getTranslation("OtpIntroTitle")), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.getTranslation("OtpIntroDescription")), 1),
                _withDirectives(_createElementVNode("p", { class: "subtitle margin-bottom-40" }, _toDisplayString(_ctx.getTranslation("OtpIntroEmailDescription")) + " " + _toDisplayString(_ctx.data.email), 513), [
                  [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Email && !_ctx.hasSteps()]
                ]),
                _withDirectives(_createElementVNode("p", { class: "subtitle margin-bottom-40" }, _toDisplayString(_ctx.getTranslation("OtpIntroEmailDobDescription")), 513), [
                  [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Email && _ctx.hasSteps()]
                ]),
                _withDirectives(_createElementVNode("p", { class: "subtitle margin-bottom-40" }, _toDisplayString(_ctx.getTranslation("OtpIntroSmsDescription")) + " " + _toDisplayString(_ctx.data.phoneNumber), 513), [
                  [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Sms && !_ctx.hasSteps()]
                ]),
                _withDirectives(_createElementVNode("p", { class: "subtitle margin-bottom-40" }, _toDisplayString(_ctx.getTranslation("OtpIntroSmsDobDescription")), 513), [
                  [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Sms && _ctx.hasSteps()]
                ]),
                _withDirectives(_createElementVNode("p", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpIntroWeSendVerificationCodeTo")) + " ", 1),
                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.data.phoneNumber), 513), [
                    [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Sms]
                  ]),
                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.data.email), 513), [
                    [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Email]
                  ])
                ], 512), [
                  [_vShow, _ctx.hasSteps()]
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_r_button, {
                        class: "footer-button",
                        expanded: "",
                        onClick: _ctx.redirect,
                        onKeyup: _withKeys(_ctx.redirect, ["enter"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpIntroSendCodeButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "onKeyup"])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_r_button, {
                        class: "footer-button",
                        variant: "secondary",
                        expanded: "",
                        onClick: _ctx.goBack
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpBackButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}