import { defineCustomElements } from "@riverty/web-components/loader";
import { LogoComponent } from "./components";
import { Settings } from "./utils/settings";
import { otpKey, otpStore } from "./store";
import { vMaska } from "maska/vue";
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";

const app = createApp(App);
defineCustomElements(window);
app.component("Logo", LogoComponent);
app.use(otpStore, otpKey);
app.use(router);
app.directive("maska", vMaska);

fetch("/otp/settings.json").then(async (response) => {
    const settings = await response?.json();
    Settings.init(settings);

    app.mount("#app");
});
