enum CountryCode {
    Undefined,
    NO = 1,
    SE = 2,
    FI = 3,
    DK = 4,
    DE = 6,
    AT = 7,
    CH = 8,
    NL = 9,
    BE = 10,
}

export default CountryCode;
