import { defineComponent } from "vue";
import { checkFetch } from "@/utils/common";
import defaultLogo from "@/assets/riverty-logo-green.svg";

export default defineComponent({
    name: "LogoComponent",
    props: {
        logoUrl: {
            type: String,
            required: false,
        },
    },
    data: function () {
        return {
            logo: "",
        };
    },
    async created() {
        await this.checkAndSetLogo(this.logoUrl);
    },
    methods: {
        async checkAndSetLogo(clientLogoUrl: string | undefined) {
            if (clientLogoUrl && (await checkFetch(clientLogoUrl))) {
                this.logo = clientLogoUrl;
            } else {
                this.logo = defaultLogo;
            }
        },
    },
});
