import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icon-error.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container margin-top-56" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_10 = { class: "card-footer" }
const _hoisted_11 = { class: "footer" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm-auto mb-3" }
const _hoisted_14 = { class: "col-sm-auto order-sm-first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_r_button = _resolveComponent("r-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Logo, {
                  logoUrl: _ctx.data?.clientLogoUrl
                }, null, 8, ["logoUrl"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[2] || (_cache[2] = _createElementVNode("img", {
                  class: "align-items-start margin-bottom-28",
                  src: _imports_0,
                  alt: "Error",
                  width: "88"
                }, null, -1)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getTranslation(_ctx.isDeadEnd ? "OtpDeadEndErrorTitle" : "OtpErrorTitle")), 1),
                (_ctx.scaSessionId)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.getTranslation(_ctx.isDeadEnd ? "OtpDeadEndErrorSubTitle" : "OtpErrorSubTitle")), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _withDirectives(_createVNode(_component_r_button, {
                        class: "footer-button",
                        disabled: !_ctx.scaSessionId,
                        expanded: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reStartVerification()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpRestartButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]), [
                        [_vShow, !_ctx.isDeadEnd && !_ctx.isSmsDisabled]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_r_button, {
                        class: "footer-button",
                        disabled: !_ctx.data?.redirectUrl,
                        variant: _ctx.isDeadEnd ? 'primary' : 'secondary',
                        expanded: "",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectToMerchant()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpReturnToShopButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "variant"])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}