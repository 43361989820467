import { CheckoutInfo } from "@/entities";
import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

export interface State {
    otp: CheckoutInfo;
}

export const otpKey: InjectionKey<Store<State>> = Symbol();

export const otpStore = createStore<State>({
    state: {
        otp: new CheckoutInfo(),
    },
    mutations: {
        setOtp(state, obj) {
            state.otp = obj;
        },
    },
    plugins: [createPersistedState()],
});
