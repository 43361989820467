<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container margin-top-56">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <Logo :logoUrl="data?.clientLogoUrl" />
                            </div>
                            <div class="card-body">
                                <img class="align-items-start margin-bottom-28" src="@/assets/icon-error.svg" alt="Error" width="88" />

                                <div class="title">{{ getTranslation("OtpExpiredTitle") }}</div>
                                <p class="subtitle">{{ getTranslation("OtpExpiredDescription") }}</p>
                            </div>
                            <div class="card-footer" v-show="showBackButton">
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-sm-auto">
                                            <r-button id="redirectBtn" class="footer-button" expanded="" v-on:click="redirectToMerchant()">{{ getTranslation("OtpReturnToShopButton") }}</r-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
